<template>
  <div>
        <kendo-grid
            ref="grid"
            :data-source="PurchasingReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <transaction-invoice-payment-report-pdf ref = "transactionInvoicePaymentReportPDF"/>
  </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import transactionInvoicePaymentReportServices from '../Script/TransactionInvoicePaymentReportService.js';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import transactionInvoicePaymentReportPdf from '../../../../assets/template/TransactionInvoicePaymentReportPdfTemplate.vue';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'TransactionInvoicePaymentReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'transaction-invoice-payment-report-pdf': transactionInvoicePaymentReportPdf
    },
    props: ['dateTo','dateFrom', 'filterDate','group'],
    computed: {
        PurchasingReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var filterDate = this.$props.filterDate;
            var groupData = this.$props.group;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                filterDate: filterDate,
                            };
                            return {
                                query: transactionInvoicePaymentReportServices.readTrasanctionInvoicePaymentReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionInvoicePaymentReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetTransactionInvoicePaymentReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionInvoicePaymentReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionInvoicePaymentReport.detail.length;
                        }
                    },
                    model: {
                        fields: {
                            total: {type: "number" },
                            total_payment: {type: "number" },
                            inv_payment_date: {type: "date"},
                        }
                    }
                },
                group: groupData
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "status", title: "Status", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "inv_payment_number", title: "No. Payment Invoice", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "No. Pembayaran Invoice : #= value #",
                },
                { field: "inv_payment_date", title: "Tanggal", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(inv_payment_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "project_type", title: "Tipe Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= project_type == 1 ? "Project" : "Retail" #' 
                },
                { field: "customer_name", title: "Customer", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "invoice_numbers", title: "No. Bukti", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "total", title: "Total", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "total_payment", title: "Total Pembayaran", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "payment_method", title: "Metode Pembayaran", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "bank", title: "Bank", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "account_name", title: "Nama Akun", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width:200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {

    },
    methods: {
        async exportExcel() {
            const variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo,
                filterDate: this.filterDate,
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await transactionInvoicePaymentReportServices.transactionInvoicePaymentReportExcel(gridData, variables);
            if(gridData == null){
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_TransaksiInvoicePayment_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = transactionInvoicePaymentReportServices.transactionInvoicePaymentReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();
                
                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            // var gridData = this.$refs.grid.kendoWidget()._data;
            
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.transactionInvoicePaymentReportPDF.generatePDF(gridData);
            //     }else if (result.isDenied) {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var filterDate = this.$props.filterDate;
            
            const routeData = this.$router.resolve({name: 'Transaction Invoice Payment Report Html Template', params: { startdate:dateFrom, enddate:dateTo, filterDate:filterDate }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    },
}
</script>