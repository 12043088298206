<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Sales - {{GroupBy}}</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>   
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tipe Detail</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="TypeDetail" :options="TypeDetailData" v-model="TypeDetail" @input="getQuantity()" />
                                <label id="errorTypeDetail" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Status</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Status" :options="StatusData" v-model="Status" @input="getQuantity()" />
                                <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Area</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Area" :options="AreaData" v-model="Area" @input="getQuantity()" />
                                <label id="errorArea" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow class="pb-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tipe Project</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="ProjectType" :options="ProjectTypeData" v-model="ProjectType" @input="getQuantity()" />
                                <label id="errorProjectType" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow >
                            <CCol class="sm-3">
                                <button id="view" class="btn btn-primary" style="float:left"
                                    @click="exportExcel()">
                                    <i class="fa fa-file-excel-o"></i> Excel
                                </button>
                                &nbsp;
                                <button id="view" class="btn btn-secondary"
                                    @click="generatePDF()">
                                    <i class="fa fa-file-pdf-o"></i> PDF
                                </button>
                            </CCol>
                        </CRow>
                    </CCol>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Quantity Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol class="sm-1">
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Revisi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{RevisiQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Checking</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{CheckingQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Approved</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{ApprovedQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Process</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{ProcessQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteQty}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Finish</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{FinishQty}}</label>
                                        </CCol>
                                    </CRow>
                                    
                                </CCol>
                            </CRow>
                        </div>
                        <CRow class="pb-3 pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Customer</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Customer" :options="CustomerData" v-model="Customer" @input="getQuantity()" />
                                <label id="errorCustomer" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                    <!-- Total Vat Per Status -->
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-1">
                                <label class="form-label font-weight-bold">Total Price Per Status</label>
                            </CCol>
                        </CRow>
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol>
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">New</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{NewTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Revisi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{RevisiTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                    <CCol class="sm-1">
                                            <label class="form-label font-weight">Checking</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{CheckingTotal}}</label>
                                    </CCol> 
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Approved</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{ApprovedTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Process</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{ProcessTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Delete</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{DeleteTotal}}</label>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Finish</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{FinishTotal}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                        <CRow class="pb-3 pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Sales</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Sales" :options="SalesData" v-model="Sales" @input="getQuantity()" />
                                <label id="errorSales" class="form-error" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <br/>
                
                <CRow>
                    <CCol>
                        <div class="col-xs-6 text-light pb-2">
                            <button id="ByRequest" class="btn btn-secondary" @click="iReqClick()">
                                By Purchase Order
                            </button>
                            &nbsp;
                            <button id="ByItem" class="btn btn-secondary" @click="iItemClick()">
                                By Item
                            </button>
                        </div>
                        <transaction-purchase-order-report-grid 
                            ref="transactionPurchaseOrderReportGrid" 
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :group="group"
                            :typeDetail="TypeDetail"
                            :status="Status"
                            :area="Area"
                            :customer="Customer"
                            :sales="Sales"
                            :projectType="ProjectType"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import transactionPurchaseOrderReportGrid from '../TransactionPurchaseOrderReport/Grid/TransactionPurchaseOrderReportGrid.vue'
import transactionPurchaseOrderReportService from '../TransactionPurchaseOrderReport/Script/TransactionPurchaseOrderReportService.js'
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone, project_type } from '../../../infrastructure/constant/variable';

export default {
    name: 'TransactionPurchaseOrderReport',
    components: {
        'transaction-purchase-order-report-grid': transactionPurchaseOrderReportGrid,
        'date-picker': datePicker
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();
        this.onChangeDateType();
        
        this.getQuantity();
        this.group = [{field:"purchase_order_number"}]; 
        this.GroupBy = 'Purchase Order'; 

        this.TypeDetailData = await transactionPurchaseOrderReportService.getTypeDetailQuery();
        this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_purchase_order');
        this.AreaData = await transactionPurchaseOrderReportService.getAreaQuery();
        this.CustomerData = await transactionPurchaseOrderReportService.getCustomerQuery();
        this.SalesData = await transactionPurchaseOrderReportService.getSalesQuery();
        this.ProjectTypeData = project_type;
    },
    data(){
        return{
            gridReload: 0,
            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,
            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,
            NewQty: '',
            RevisiQty:'',
            CheckingQty:'',
            ApprovedQty:'',
            ProcessQty:'',
            FinishQty:'',
            DeleteQty :'',

            NewTotal:'',
            RevisiTotal:'',
            CheckingTotal:'',
            ApprovedTotal:'',
            ProcessTotal:'',
            FinishTotal:'',
            DeleteTotal:'',
            Error:0,
            group: [],
            GroupBy: '',
            DateTypeData: [],
            DateType: report.default_date,

            TypeDetailData: [],
            TypeDetail: null,

            StatusData: [],
            Status: null,

            AreaData: [],
            Area: null,

            CustomerData: [],
            Customer: null,

            SalesData: [],
            Sales: null,

            ProjectTypeData: [],
            ProjectType: null,
        }
    },
    methods: {
        iReqClick(){
            this.group = [{field: "purchase_order_number"}];
            this.GroupBy = 'Purchase Order'
            this.gridReload++;
        },
        iItemClick(){            
            this.group = [{field: "product_code"}];
            this.GroupBy = 'Item'
            this.gridReload++;
        },
        async getQuantity(){
            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
                typeDetail: this.TypeDetail == null ? null : this.TypeDetail.value,
                status: this.Status == null ? null : this.Status.value,
                areaCode: this.Area == null ? null : this.Area.value,
                customer: this.Customer == null ? null : this.Customer.value,
                sales: this.Sales == null ? null : this.Sales.value,
                projectType: this.ProjectType == null ? null : this.ProjectType.value
            };
            
            var dataPurchaseOrderReportSummary = await transactionPurchaseOrderReportService.getTransactionPurchaseOrderReport(variables);
            var summaryQuantity = dataPurchaseOrderReportSummary.summary.quantity_per_status;
            var summaryTotal = dataPurchaseOrderReportSummary.summary.total_price_per_status;

            this.NewQty = summaryQuantity.new;
            this.RevisiQty = summaryQuantity.revisi;
            this.CheckingQty = summaryQuantity.checking;
            this.ApprovedQty = summaryQuantity.approved;
            this.ProcessQty = summaryQuantity.process;
            this.DeleteQty = summaryQuantity.delete;
            this.FinishQty = summaryQuantity.finish;

            this.NewTotal = kendo.format('{0:N0}', summaryTotal.new);
            this.RevisiTotal = kendo.format('{0:N0}', summaryTotal.revisi);
            this.CheckingTotal = kendo.format('{0:N0}', summaryTotal.checking);
            this.ApprovedTotal = kendo.format('{0:N0}', summaryTotal.approved);
            this.ProcessTotal = kendo.format('{0:N0}', summaryTotal.process);
            this.DeleteTotal = kendo.format('{0:N0}', summaryTotal.delete);
            this.FinishTotal = kendo.format('{0:N0}', summaryTotal.finish);

            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            await this.getQuantity();
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                await this.getQuantity();
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                await this.getQuantity()
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.transactionPurchaseOrderReportGrid.exportExcel();
        },
        generatePDF(){
            const routeData = this.$router.resolve({name: 'Transaction Purchase Order Report Html Template', params: { startdate: this.DateFrom, enddate: this.DateTo }});
            window.open(routeData.href, '_blank');
            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.transactionPurchaseOrderReportGrid.generatePDF();
            //     }else if (result.isDenied) {
            //         const routeData = this.$router.resolve({name: 'Transaction Purchase Order Report Html Template', params: { startdate: this.DateFrom, enddate: this.DateTo }});
            //         window.open(routeData.href, '_blank');
            //     }
            // });
        }
    }
}
</script>

<style scoped>
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>