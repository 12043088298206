<template>
  <div>
        <kendo-grid
            ref="grid"
            :data-source="PurchasingReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <transaction-delivery-order-report-pdf ref = "transactionDeliveryOrderReportPDF"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import transactionDeliveryOrderReportServices from '../Script/TransactionDeliveryOrderReportService.js';
import { kendo_grid, sweet_alert, datetime_zone } from '../../../../infrastructure/constant/variable';
import transactionDeliveryOrderReportPdf from '../../../../assets/template/TransactionDeliveryOrderReportPdfTemplate.vue'
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'TransactionDeliveryOrderReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'transaction-delivery-order-report-pdf': transactionDeliveryOrderReportPdf
    },
    props: ['dateTo','dateFrom','group'],
    computed: {
        PurchasingReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var groupData = this.$props.group;
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to
                            };
                            return {
                                query: transactionDeliveryOrderReportServices.readTrasanctionDeliveryOrderReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionDeliveryOrderReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetTransactionDeliveryOrderReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionDeliveryOrderReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionDeliveryOrderReport.detail.length;
                        }
                    },
                    model: {
                        fields: {
                            quantity: {type: "number" },
                            delivery_order_date: {type: "date"},
                        }
                    }
                },
                group: groupData,
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "delivery_order_number", title: "No. DO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "No. Delivery : #= value #",},
                { field: "customer_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "Kode Barang : #= value #", },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Jumlah", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "delivery_order_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(delivery_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "project_type", title: "Tipe Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= project_type == 1 ? "Project" : "Retail" #' 
                },
                { field: "sp_number", title: "NO. SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "purchase_order_number", title: "No. PO Cust", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {

    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        async exportExcel() {
            const variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo
            };
            // var dataItem = await transactionDeliveryOrderReportService.getTransactionDeliveryOrderReport(variables);
            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await transactionDeliveryOrderReportServices.transactionDeliveryOrderReportExcel(gridData, variables);

            if (gridData == null){
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_TransaksiDeliveryOrder_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = transactionDeliveryOrderReportServices.transactionDeliveryOrderReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            // var gridData = this.$refs.grid.kendoWidget()._data;

            // this.$swal(sweet_alert.print).then((result) => {
            //     if (result.isConfirmed == true) {
            //         this.$refs.transactionDeliveryOrderReportPDF.generatePDF(gridData);
            //     }else if (result.isDenied) {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;

            const routeData = this.$router.resolve({name: 'Transaction Delivery Order Report Html Template', params: { startdate: dateFrom, enddate: dateTo }});
            window.open(routeData.href, '_blank');
            //     }
            // });
        }
    },
}
</script>