<template>
  <div>
        <kendo-grid
            ref="grid"
            :data-source="TransactionInvoiceReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <transaction-invoice-report-pdf ref = "transactionInvoiceReportPDF"/>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import transactionInvoiceReportServices from '../Script/TransactionInvoiceReportService.js';
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import transactionInvoiceReportPdf from '../../../../assets/template/TransactionInvoiceReportPdfTemplate.vue'

export default {
    name: 'TransactionInvoiceReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'transaction-invoice-report-pdf': transactionInvoiceReportPdf
    },
    props: ['dateTo', 'dateFrom', 'statusPayment'],
    computed: {
        TransactionInvoiceReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var statusPaymentData = this.$props.statusPayment;
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                statusPayment: (statusPaymentData != null ? statusPaymentData.value : statusPaymentData),
                                status: ['New', 'Sent']
                            };
                            return {
                                query: transactionInvoiceReportServices.readTrasanctionInvoiceReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionInvoiceReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetTransactionInvoiceReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionInvoiceReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionInvoiceReport.detail.length;
                        }
                    },
                    // model: {
                    //     fields: {
                    //         price: {type: "number" },
                    //         total: {type: "number" },
                    //         fulfilled_quantity: {type: "number" },
                    //         invoice_date: {type: "date"},
                    //     }
                    // }
                },
                group: [
                    { field: "invoice_number" }
                ]

            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "invoice_date", title: "Tanggal Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "invoice_number", title: "No. Faktur", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "delivery_order_number", title: "No. SJ", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "tax_invoice", title: "No. Faktur Pajak", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "product_code", title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "fulfilled_quantity", title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "total_conv_amount", title: "Quantity Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "price", title: "Harga", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "price_discount", title: "DPP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    footerTemplate: "" ,format:"{0:N0}"
                },
                { field: "ppn_out", title: "PPN Out", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "vat", title: "Piutang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "customer_name", title: "Keterangan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "due_date", title: "Jatuh Tempo", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(invoice_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "purchase_order_number", title: "No. PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "outstanding_amount", title: "Outstanding", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "status_payment", title: "Status Pembayaran", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {

    },
    methods: {
        async exportExcel() {
            const variables = {
                startDate: this.DateFrom,
                endDate: this.DateTo
            };

            // var dataItem = await transactionInvoiceReportService.getTransactionInvoiceReport(variables);
            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await transactionInvoiceReportServices.transactionInvoiceReportExcel(gridData, variables);
            if(gridData == null){
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_TransaksiInvoice_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = transactionInvoiceReportServices.transactionInvoiceReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }

        },
        generatePDF(){
            var gridData = this.$refs.grid.kendoWidget()._data;
            this.$refs.transactionInvoiceReportPDF.generatePDF(gridData);
        }
    },
}
</script>