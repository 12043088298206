<template>
  <div>
        <kendo-grid
            ref="grid"
            :data-source="PurchasingReport"
            :filterable="false"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
        <transaction-purchase-order-report-pdf ref = "transactionPurchaseOrderReportPDF"/>
  </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import transactionPurchaseOrderReportServices from '../Script/TransactionPurchaseOrderReportService.js';
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import transactionPurchaseOrderReportPdf from '../../../../assets/template/TransactionPurchaseOrderReportPdfTemplate.vue'

export default {
    name: 'TransactionPurchaseOrderReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'transaction-purchase-order-report-pdf': transactionPurchaseOrderReportPdf
    },
    props: ['dateTo','dateFrom','group','typeDetail','status','area','customer','sales','projectType'],
    computed: {
        PurchasingReport () {
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var groupData = this.$props.group;
            var typeDetail = this.$props.typeDetail;
            var status = this.$props.status;
            var area = this.$props.area;
            var customer = this.$props.customer;
            var sales = this.$props.sales;
            var projectType = this.$props.projectType;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                typeDetail: typeDetail == null ? null : typeDetail.value,
                                status: status == null ? null : status.value,
                                areaCode: area == null ? null : area.value,
                                customer: customer == null ? null : customer.value,
                                sales: sales == null ? null : sales.value,
                                projectType: projectType == null ? null : projectType.value,
                            };
                            return {
                                query: transactionPurchaseOrderReportServices.readTrasanctionPurchaseOrderReport(),
                                variables:variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPurchaseOrderReport.detail == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPurchaseOrderReport.detail;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPurchaseOrderReport.detail == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPurchaseOrderReport.detail.length;
                        }
                    },
                    model: {
                        fields: {
                            down_payment: {type: "number" },
                            total: {type: "number" },
                            quantity: {type: "number" },
                            purchase_order_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                },
                group: groupData

            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} ,},
                { field: "purchase_order_number", width: 200, title: "No. PO", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "PO Number : #= value #",  },
                { field: "purchase_order_date", title: "Tanggal PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "customer_name", title: "Nama Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { sortable: true, field: "price_discount_per_unit", title: "Amount", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Buat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "sp_number", title: "No. SP", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { sortable: true, field: "price_discount", title: "Total", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "purchase_order_type", title: "Tipe Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "payment_method", title: "Metode Pembayaran", width: 200,headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_type", title: "Jatuh Tempo (hari)", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"},
                    template: '#= term_of_payment #' 
                },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_by", title: "Dibuat Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "down_payment", title: "Down Payment", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, format:"{0:N0}" },
                { field: "invoice_number", title: "No. Invoice", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "product_code", title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                  groupHeaderTemplate: "Kode Barang : #= value #",  },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Jumlah", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
            sortableConfig: {
                mode: "multiple"
            },
        };
    },
    mounted: async function() {
        
    },
    methods: {
        columnFile(e){
            return this.$globalfunc.gridFileViewButton(e.attachment);
        },
        async exportExcel (){
            var dateTo = this.$props.dateTo;
            var dateFrom = this.$props.dateFrom;
            var typeDetail = this.$props.typeDetail;
            var status = this.$props.status;
            var area = this.$props.area;
            var customer = this.$props.customer;
            var sales = this.$props.sales;
            var projectType = this.$props.projectType;
            
            var variables = {
                startDate: dateFrom + datetime_zone.date_from,
                endDate: dateTo + datetime_zone.date_to,
                typeDetail: typeDetail == null ? null : typeDetail.value,
                status: status == null ? null : status.value,
                areaCode: area == null ? null : area.value,
                customer: customer == null ? null : customer.value,
                sales: sales == null ? null : sales.value,
                projectType: projectType == null ? null : projectType.value,
            };
            var dataItem = await transactionPurchaseOrderReportServices.getTransactionPurchaseOrderReport(variables);
            var data = await transactionPurchaseOrderReportServices.transactionPurchaseOrderReportExcel(dataItem.detail, variables);
            
            if(dataItem.detail == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else{
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_TransaksiPurchaseOrder_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = transactionPurchaseOrderReportServices.transactionPurchaseOrderReportExcelHeaderSize();
                
                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename); 
            }
        },
        generatePDF(){
            var gridData = this.$refs.grid.kendoWidget()._data;
            this.$refs.transactionPurchaseOrderReportPDF.generatePDF(gridData);
        }
    },  
}
</script>